<template>
    <div>
        <div style="width:100%; background-color:#FFF;">
            <div v-if="!selectedImages.length" ><p class="pt-3 pl-3 pr-3" style="font-size:15px"><strong>{{title}}</strong></p><hr></div>
            <div v-else>
                <div class="dF" style="justify-content:space-between">
                    <div class="pt-3 pl-3 pr-3" style="font-size:15px"><strong>{{`${selectedImages.length} images selected`}}</strong></div>
                    <div @click="deleteImage" class="pt-3 pl-3 pr-3 dF aC" style="font-size:15px; cursor:pointer"><i style="color:#FD647C" class="fe fe-trash mr-3" /><strong>Delete Images</strong></div>
                </div><hr>
            </div>

            <div class="pl-3 pr-3 pb-3" style="height:100%">
                <div v-if="!galleryImages.length" style="width:100%; border-radius:4px">
                    <ImageBox @callback="addImage"/>
                </div>
                <div v-else class="dF hide-scrollbar" style="width:100%; overflow-x:scroll">
                    <draggable class="dF" :list="galleryImages" :delay-on-touch-only="true" :delay="100">
                    <div v-for="(image, imageI) in galleryImages" :key="imageI">
                        <div class="mr-4" style="height:120px; width:120px;">
                            <div class="relative previewImage" style="height:100%; width:100%">
                                <div class="absolute" style="top:0; right:0; z-index:100">
                                    <a-checkbox :checked="selectedImages.includes(image)" @change="bulkSelectImage(image)"/>
                                </div>
                                <div class="absolute zoomButton" style="height:100%; width:100%; background-color:rgba(64, 69, 76, 0.5);">
                                    <div @click="previewImage(image)" class="w-full dF aC jC h-full">
                                        <i style="color:#fff; font-size:20px" class="fe fe-search" />
                                    </div>
                                </div>
                                <img style="width:100%; height:100%; object-fit:cover" :src="image.url" />
                            </div>
                        </div>
                    </div>
                    </draggable>
                    <div style="width:120px; height:120px; border-radius:4px; cursor:pointer">
                        <div @click="$store.commit('MEDIA_SELECT',{callback:addImage,type:'images'})" class="dF aC jC" style="width:120px; height:120px; background-color:#FAF9F9; border:dashed 1px #D0C9D6; color:#FD9026; border-radius:4px">
                            <div>Add Image</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ImageBox from '@/components/models/ImageBox'
import draggable from 'vuedraggable'
export default {
    components:{
        ImageBox,draggable
    },
    props:{
        title:{
            default:'',
            type:String
        },
        galleryImages:{
            default:() => [],
            type:Array
        }
    },
    watch:{
        galleryImages:{
            handler(val) {
                console.log('changeeeee', this.galleryImages)
                this.$emit('galleryImages' , this.galleryImages)
            }
        }
    },
    data() {
        return {
            selectedImages:[],
            // galleryImages:[]
        }
    },
    methods:{
        previewImage(data){
            this.$emit("previewGallery" ,data)
        },
        addImage(data){
            let image = {
                url:'',
                id:'',
            }
            if (typeof(data) == 'object') {
                image.url = data.url
                image.id = Date.now()
                this.galleryImages.push(image)
            } else {
                image.url = data
                image.id = Date.now()
                this.galleryImages.push(image)
            }
        },
        bulkSelectImage(data) {
            if (this.selectedImages.includes(data)) {
                let index = this.selectedImages.findIndex(x => x.id == data.id)
                this.selectedImages.splice(index,1)
            } else {
                this.selectedImages.push(data)
            }
            console.log('selectedImages', this.selectedImages)
        },
        deleteImage(){
            this.selectedImages.forEach(sel => {
                let index = this.galleryImages.findIndex(gal => gal.id == sel.id)
                this.galleryImages.splice(index, 1)
            })
            this.selectedImages = []
        }
    }

}
</script>

<style scoped>
.zoomButton {
    opacity:0;
}
.previewImage:not(:hover) .zoomButton{
    opacity:0;
    transform: opacity 0.3s ease-in;
}
.previewImage:hover .zoomButton{
    opacity:1;
    border: 1px solid var(--orange);
    transform: opacity 0.3s ease-in;
}
</style>
